import videoPoster1 from "@/assets/images/video_cover_picture/videoposter1.jpg";
import videoPoster2 from "@/assets/images/video_cover_picture/videoPoster2.jpg";
import videoPoster3 from "@/assets/images/video_cover_picture/videoPoster3.jpg";
import videoPoster4 from "@/assets/images/video_cover_picture/videoPoster4.jpg";
import videoPoster5 from "@/assets/images/video_cover_picture/videoPoster5.jpg";
import videoPoster6 from "@/assets/images/video_cover_picture/videoPoster6.jpg";
import videoPoster7 from "@/assets/images/video_cover_picture/videoPoster7.jpg";
import videoPoster8 from "@/assets/images/video_cover_picture/videoPoster8.jpg";
import videoPoster9 from "@/assets/images/video_cover_picture/videoPoster9.jpg";
import videoPoster10 from "@/assets/images/video_cover_picture/videoposter10.jpg";

// 2:02:03
// 2:03
// 00:59
// 视频时长规则，时位、分位为个位数时作为最大单位时，不需要添0补充。分秒不作为最大单位时需要为各位数添加0作为补充。当视频小于1分钟需要为分位添加00
// bilibili 取视频播放地址方法：
// console.log(`//player.bilibili.com/player.html?aid=${window.playerInfo.aid}&bvid=${window.playerInfo.bvid}&cid=${window.playerInfo.cid}&page=1`)
export default [
  {
    id: 10,
    title: "DEC功能展示",
    titleEn: "DEC Functions",
    duration: "02:03",
    isPlay: true,
    src: "https://dec-install.oss-cn-beijing.aliyuncs.com/DEC-introduction.mp4",
    poster: videoPoster10,
    platform: "video",
  },
  {
    id: 1,
    title: "DEC HUB安装",
    titleEn: "DEC HUB",
    duration: "01:13",
    isPlay: false,
    src: "//player.bilibili.com/player.html?aid=900618194&cid=832513221&page=1",
    poster: videoPoster1,
    platform: "bilibili",
  },
  {
    id: 2,
    title: "赋予材质",
    titleEn: "Material",
    duration: "01:44",
    isPlay: false,
    src: "//player.bilibili.com/player.html?aid=943049035&bvid=BV1qW4y1i7rr&cid=832513225&page=1",
    poster: videoPoster2,
    platform: "bilibili",
  },
  {
    id: 3,
    title: "摄像机设置",
    titleEn: "Cine Camera",
    duration: "02:57",
    isPlay: false,
    src: "//player.bilibili.com/player.html?aid=388042966&bvid=BV1jd4y1676a&cid=832513229&page=1",
    poster: videoPoster3,
    platform: "bilibili",
  },
  {
    id: 4,
    title: "保存UE关卡地图",
    titleEn: "Save LevelMap",
    duration: "01:53",
    isPlay: false,
    src: "//player.bilibili.com/player.html?aid=560590614&bvid=BV1He4y187hH&cid=832513169&page=1",
    poster: videoPoster4,
    platform: "bilibili",
  },
  {
    id: 5,
    title: "渲染",
    titleEn: "Render",
    duration: "03:38",
    isPlay: false,
    src: "//player.bilibili.com/player.html?aid=688103915&bvid=BV1Z24y1Z7WC&cid=832513233&page=1",
    poster: videoPoster5,
    platform: "bilibili",
  },
  {
    id: 6,
    title: "同步Maya与UE相机视角",
    titleEn: "Set Camera",
    duration: "01:16",
    isPlay: false,
    src: "//player.bilibili.com/player.html?aid=645535733&bvid=BV1fY4y1K79E&cid=832513235&page=1",
    poster: videoPoster6,
    platform: "bilibili",
  },
  {
    id: 7,
    title: "导出Maya场景",
    titleEn: "Export Scene",
    duration: "01:35",
    isPlay: false,
    src: "//player.bilibili.com/player.html?aid=218108196&bvid=BV1s8411t7oe&cid=832513173&page=1",
    poster: videoPoster7,
    platform: "bilibili",
  },
  {
    id: 8,
    title: "UE中生成Maya资产节点",
    titleEn: "Transform Delegate",
    duration: "03:58",
    isPlay: false,
    src: "//player.bilibili.com/player.html?aid=985549659&bvid=BV1Pt4y1j7dq&cid=832513072&page=1",
    poster: videoPoster8,
    platform: "bilibili",
  },
  {
    id: 9,
    title: "Maya中生成UE资产节点",
    titleEn: "Engine Node.mp4",
    duration: "02:37",
    isPlay: false,
    src: "//player.bilibili.com/player.html?aid=645589337&bvid=BV1NY4y1T7Yp&cid=832513179&page=1",
    poster: videoPoster9,
    platform: "bilibili",
  },
];
